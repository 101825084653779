import React from 'react';
import CustomBreadcrumb from '../../../components/CustomBreadcrumb';
import Layout from '../../../components/Layout';
import Seo from '../../../components/Seo';
import LandingPageCta from '../../../components/LandingPageCta';
import VideoIframe from '../../../components/VideoIframe';

const GuideToPriceValue = ({ pageContext, location }) => {
	const {
		breadcrumb: { crumbs }
	} = pageContext;

	const handleClick = (e) => {
		e.target.classList.toggle('active');

		const content = e.target.nextElementSibling;

		if (content.style.maxHeight) {
			content.style.maxHeight = null;
		} else {
			content.style.maxHeight = content.scrollHeight + 'px';
		}
	};

	return (
		<Layout>
			<Seo
				title="How to Choose A Hearing Aid"
				description="We believe it's essential for everyone to hear well to live well and a key way to do this is by having the proper hearing aid for your needs."
			/>
			<div className="o-row-4p5">
				<section className="c-value-hero">
					<div className="o-wrapper">
						<h1 className="c-value-headline u-mobile-hidden">
							Hearing Aids:
							<br />
							Your Guide to Value & Price
						</h1>
						<h1 className="c-value-headline u-desktop-hidden">Hearing Aid Value & Price Guide</h1>
					</div>
				</section>
			</div>
			<div className="o-row-4p5">
				<section className="o-wrapper o-wrapper--small">
					<div className="o-row-2p5">
						<CustomBreadcrumb location={location} crumbs={crumbs} />
					</div>
					<div className="o-row-2">
						<p className="c-value-body-copy">
							Do you realize how important good hearing is when it comes to staying connected to the world around you,
							including those you love? If you're like most people, you don't. But there's a good chance you're already
							suffering some of the consequences.
						</p>
					</div>
					<div className="o-row-2">
						<p className="c-value-body-copy">
							Hearing loss is now the third most chronic condition in the United States — affecting over 48 million — or
							1 out of 7 Americans. Because of this, more research has been done to understand hearing loss and how it
							affects you. Hearing loss does not mean you can't hear in terms of volume, it means you have trouble
							understanding what people say due to a lack of clarity. Study after study shows that untreated hearing
							loss has negative effects on your mental sharpness, health, relationships, income, and how others perceive
							you.
						</p>
					</div>
					<div className="o-row-2">
						<p className="c-value-body-copy">
							Since we truly believe it's essential for everyone to hear well to live well, we've pulled together the
							most important information you need to know so you can take action now.
						</p>
					</div>
					<div className="o-row-2">
						<p className="c-value-body-copy">In this guide we cover four major topics:</p>
						<ul className="c-value-usps">
							<li>Why your hearing is essential</li>
							<li>Why you need a personalized solution</li>
							<li>The factors that can make a difference for you</li>
							<li>Why it's one of the smartest investments you can make</li>
						</ul>
					</div>
				</section>
			</div>
			<div className="o-row-4p5">
				<section className="o-wrapper">
					<div className="o-flush-mobile">
						<img
							src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/price-value/sec-1.jpg"
							alt="couple together"
							loading="lazy"
							className="o-fluid-img"
						/>
						<div className="c-value-showcase">
							<h2 className="c-value-showcase__copy">
								<span className="c-value-showcase__num">1</span> Your Hearing Is Essential
							</h2>
						</div>
					</div>
				</section>
			</div>
			<div className="o-row-4p5">
				<section className="o-wrapper o-wrapper--small">
					<div className="o-row-2">
						<p className="c-value-body-copy">
							From morning to night, your brain relies on your sense of hearing to keep you aware, informed and engaged
							with your environment. When you have hearing loss, your brain needs to fill in the gaps to compensate for
							what your hearing is missing. That puts a strain on other brain functions making it harder to follow the
							conversation, come up with appropriate responses and ask thoughtful questions. Once you feel behind in a
							conversation, it can feel impossible to catch up — making you feel embarrassed, frustrated and exhausted
							and put you at risk for bigger problems down the road.
						</p>
					</div>
					<div className="o-row-2">
						<p className="c-value-body-copy">
							Hearing plays a big role in the moments that really matter to you — like hearing your baby's coos,
							laughing with friends, or connecting with a new client. Imagine all the moments that are still to come,
							but you're only able to experience a fraction of it.
						</p>
					</div>
					<div className="o-row-4p5">
						<p className="c-value-body-copy">
							It's now crystal clear that untreated hearing loss has a negative effect on your quality of life. The
							choice is in your hands — you can either invest in a solution or pay the price of hearing loss.
						</p>
					</div>
					<div className="o-row-4p5">
						<div className="c-value-box">
							<div className="c-value-box__icon">
								<img
									src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/price-value/start-quote.svg"
									className="o-fluid-img"
									loading="lazy"
									alt=""
								/>
							</div>
							<p className="c-value-box__copy">
								It got to a point where I was saying "Huh?" all the time.
								<span className="u-text-italic">
									So I was constantly missing out on conversations with people I love.
								</span>{' '}
								Now I can hear everything and participate fully again thanks to my new hearing aids.{' '}
								<strong>- Jimmy, TX</strong>
							</p>
							<div className="c-value-box__icon end-quote">
								<img
									src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/price-value/end-quote.svg"
									className="o-fluid-img"
									loading="lazy"
									alt=""
								/>
							</div>
						</div>
					</div>
					<div className="o-row-2">
						<h3 className="c-section-sub-title">Hearing Loss Strains Communication</h3>
					</div>
					<div className="o-row-4p5">
						<p className="c-value-body-copy">
							One of the most common things we hear from customers is, “I can hear when somebody talks to me, but I
							can't understand what they're saying.” This is because hearing loss typically affects the higher pitched
							sounds responsible for understanding speech — consonant sounds like H, K, P, F, S, TH, CH and SH. For
							example, you might confuse, “What time is it?” with “What kind is it?” And as a result, everyday
							activities like watching TV or talking with your family can become less enjoyable and more exhausting.
							Fortunately, hearing aids amplify the specific frequencies or pitches you struggle with to provide the
							clarity you are missing.
						</p>
					</div>
					<div className="o-row-2">
						<h3 className="c-section-sub-title">Hearing Loss Affects Your Health And Happiness</h3>
					</div>
					<div className="o-row-4p5">
						<p className="c-value-body-copy">
							Because it's gradual and you don't experience physical pain, you may not see how hearing loss is affecting
							your quality of life. But in a survey of 2,300 adults over the age of 50, the National Council on Aging
							(NCOA) debunks the myth that untreated hearing loss is harmless. Researchers found that people with
							untreated hearing loss reported feeling excluded, embarrassed, lonely and depressed because of
							communication problems in social situations.
						</p>
					</div>
					<div className="o-row-4p5">
						<div className="c-value-box">
							<div className="c-value-box__icon u-center-mobile">
								<img
									src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/price-value/hearts.svg"
									className="o-fluid-img"
									loading="lazy"
									alt=""
								/>
							</div>
							<p className="c-value-box__copy">
								In another survey, <strong>7 out of 10 people</strong> said that hearing aids not only{' '}
								<strong>improved their relationships,</strong> but that they <strong>also had more energy.</strong>
							</p>
							<div className="c-value-box__icon u-center-mobile">
								<img
									src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/price-value/hearts.svg"
									className="o-fluid-img"
									loading="lazy"
									alt=""
								/>
							</div>
						</div>
					</div>
					<div className="o-row-2">
						<h3 className="c-section-sub-title">Hear Loss Affects Your Work And Wages</h3>
					</div>
					<div className="o-row-4p5">
						<div className="c-value-side-by-side">
							<div className="o-row-1">
								<div className="o-row-1">
									<p className="c-value-body-copy">
										Because good communication and quick thinking is important to your career success, hearing loss is
										proven to lead to problems in the workplace, too. Unfortunately, you may be perceived as
										disinterested or less competent if you don't respond appropriately, which can lead to lost
										opportunities and lower wages. But when it's easier to understand what someone is saying while
										you’re wearing hearing aids, your brain can put more energy toward critical thinking, communication,
										creativity, and collaboration — resulting in career success.
									</p>
								</div>
								<div className="o-row">
									<p className="c-value-body-copy">
										Since 2009, EuroTrak has surveyed over 175,000 people with hearing loss and hearing aids and found:
									</p>
								</div>
							</div>
							<div className="o-row">
								<div className="c-value-side-by-side__img">
									<img
										src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/price-value/business.jpg"
										alt="coworkers together"
										loading="lazy"
										className="o-fluid-img"
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="o-row-4p5">
						<div className="c-value-box">
							<div className="c-value-box__icon u-center-mobile">
								<img
									src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/price-value/dollars.svg"
									className="o-fluid-img"
									loading="lazy"
									alt=""
								/>
							</div>
							<p className="c-value-box__copy">
								<strong>91% of hearing aid wearers</strong> reported an <strong>improvement at work</strong> and{' '}
								<strong>$44,000 of higher earnings</strong> throughout their remaining working years.
							</p>
							<div className="c-value-box__icon u-center-mobile">
								<img
									src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/price-value/dollars.svg"
									className="o-fluid-img"
									loading="lazy"
									alt=""
								/>
							</div>
						</div>
					</div>
					<div className="o-row-2">
						<h3 className="c-section-sub-title">Hearing Loss Accelerates Cognitive Decline</h3>
					</div>
					<div className="o-row-2">
						<p className="c-value-body-copy">
							According to a 25-year study by the University of Bordeaux, hearing loss is associated with accelerated
							cognitive decline. In addition, multiple studies by Dr. Frank Lin at Johns Hopkins show clear associations
							between hearing loss and brain health. Even mild levels of untreated hearing loss are connected to
							increased risks of cognitive decline and dementia. Fortunately, studies also show that using hearing aids
							early reduces the risk of cognitive decline and dementia associated with hearing loss. Dr. Lin explains
							that hearing loss requires the brain to constantly put more energy into processing degraded sound, which
							leaves less energy for other tasks like memory, thinking and balance. That’s why getting hearing aids
							sooner rather than later is key.
						</p>
					</div>
					<div className="o-row-2">
						<h3 className="c-section-sub-title">Hearing Loss Is Progressive And Will Only Get Worse</h3>
					</div>
					<div className="o-row-2">
						<p className="c-value-body-copy">
							The most common hearing loss is sensorineural, which is often the result of excessive noise exposure or
							aging, or a combination of the two. Since hearing loss is a gradual decline for most people, it’s often
							overlooked until it starts having a real impact on your quality of life and the people you care about
							start pointing it out. There’s also a good chance you’re making it worse by continuing to increase the
							volume on your phone, TV, radio, or headphones. This is why untreated hearing loss will only worsen over
							time. Fortunately, studies have shown that hearing aids are the only proven way to slow down the
							progression of hearing loss and stimulate brain activity.
						</p>
					</div>
				</section>
			</div>
			<div className="o-row-4p5">
				<section className="o-wrapper">
					<div className="o-flush-mobile">
						<img
							src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/price-value/sec-2.jpg"
							alt="Woman listening to music"
							loading="lazy"
							className="o-fluid-img"
						/>
						<div className="c-value-showcase">
							<h2 className="c-value-showcase__copy">
								<span className="c-value-showcase__num">2</span> You Need A Personalized Solution
							</h2>
						</div>
					</div>
				</section>
			</div>
			<div className="o-row-4p5">
				<section className="o-wrapper o-wrapper--small">
					<div className="o-row-4p5">
						<p className="c-value-body-copy">
							Hearing aids aren't one-size fits all. No two ears and level of hearing loss are the same. That's why it's
							important to have a professional assess your hearing loss and determine the best medical-grade hearing
							aids for your individual needs. The most important thing is{' '}
							<span className="u-text-italic">for you to do something</span>, because
							<span className="u-text-italic">any</span>
							medical-grade hearing aids are better than none at all. A tailored solution will focus on these 5 things:
						</p>
					</div>
					<div className="o-row-2">
						<h3 className="c-section-sub-title">Your Hearing Loss</h3>
					</div>
					<div className="o-row-4p5">
						<p className="c-value-body-copy">
							Your hearing loss is as unique as your fingerprint. Therefore, one of your first steps is to get a
							comprehensive hearing evaluation to determine the type and extent of your hearing loss. The evaluation
							includes an examination of the ear canal to rule out other medical conditions and helps determine the best
							hearing aid styles for your needs. You might be surprised to learn that the severity of your hearing loss
							does not affect the price you pay, but it often impacts the styles available to you.
						</p>
					</div>
					<div className="o-row-4p5">
						<div className="c-value-side-by-side">
							<div className="o-row-4p5">
								<div className="c-value-side-by-side__img c-value-side-by-side__img--left">
									<img
										src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/price-value/personal.jpg"
										alt="people laughing"
										loading="lazy"
										className="o-fluid-img"
									/>
								</div>
							</div>
							<div className="o-row-2">
								<div className="o-row-2">
									<h3 className="c-section-sub-title">Your Personal Hearing Preferences</h3>
								</div>
								<div className="o-row">
									<p className="c-value-body-copy">
										Everyone perceives sounds differently and has specific preferences. For example, some people love
										classical music while others prefer hard rock and the next group loves techno. You may prefer
										sharper sounds, while someone else likes softer sounds. A good hearing professional can fine-tune
										your hearing aids to meet your specific hearing preferences to ensure you're happy with your
										hearing.
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="o-row-2">
						<h3 className="c-section-sub-title">Your Lifestyle</h3>
					</div>
					<div className="o-row-2">
						<p className="c-value-body-copy">
							As a general rule, the more active you are, the more value you'll see in higher technology levels. When
							you’re in more challenging listening environments, you're exposed to more background noises. Sophisticated
							hearing programs filter out the noise so you can clearly hear and understand speech.
						</p>
					</div>
					<div className="o-row-2">
						<p className="c-value-body-copy">
							To determine the appropriate level of technology, you'll want to ask yourself the following questions:
						</p>
					</div>
					<div className="o-row-2">
						<ul className="c-value-usps">
							<li>
								How active are you? Are you someone who spends most of your time in busy environments like work,
								restaurants, gatherings, etc? Or do you spend much of your time quietly at home?
							</li>
							<li>What situations are the most important to you: work, family gatherings, religious services?</li>
							<li>How do you like to spend your quality time: music, grand kids, hiking, community?</li>
							<li>
								Is there something unique in your lifestyle that requires better hearing? For example, birders need to
								hear higher pitches and judges need to hear whispers when people approach the bench.
							</li>
							<li>Is there anything you’re avoiding or given up on because of your hearing loss?</li>
						</ul>
					</div>
					<div className="o-row-4p5">
						<p className="c-value-body-copy">
							By answering these questions, an expert can help guide you toward the best hearing aid technology level to
							make sure that every moment counts.
						</p>
					</div>
					<div className="o-row-4p5">
						<div className="c-value-box">
							<div className="c-value-box__icon">
								<img
									src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/price-value/start-quote.svg"
									className="o-fluid-img"
									loading="lazy"
									alt=""
								/>
							</div>
							<p className="c-value-box__copy">
								I was at a restaurant having wine with my friend, and I could hear a table of people across the room
								speaking clearly.{' '}
								<span className="u-text-italic">I was like, holy cow, this is what I've been missing!</span>
								<strong>- Helen, IN</strong>
							</p>
							<div className="c-value-box__icon end-quote">
								<img
									src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/price-value/end-quote.svg"
									className="o-fluid-img"
									loading="lazy"
									alt=""
								/>
							</div>
						</div>
					</div>
					<div className="o-row-2">
						<h3 className="c-section-sub-title">Your Style Preferences</h3>
					</div>
					<div className="o-row-2">
						<p className="c-value-body-copy">
							Hearing aids look nothing like they did even 5 years ago. There are smaller, sleeker designs, many of
							which won't even be noticed when you're wearing them. And with the surge in Bluetooth-enabled devices like
							earpods, more and more people — some who don't even have hearing loss — are wearing devices in their ears
							to improve sound. You'll want to ask yourself:
						</p>
					</div>
					<div className="o-row-2">
						<ul className="c-value-body-copy">
							<li>How important is discreteness or design?</li>
							<li>
								Do you want the added convenience of Bluetooth hearing aids, so you can easily connect your hearing aids
								to your smartphone or TV?
							</li>
							<li>Do you want rechargeable hearing aids to avoid changing tiny batteries?</li>
						</ul>
					</div>
					<div className="o-row-4p5">
						<p className="c-value-body-copy">
							It's good to work with a provider that has many options for you to choose from.
						</p>
					</div>
					<div className="o-row-4p5">
						<div className="c-value-side-by-side c-value-side-by-side--alt">
							<div className="o-row">
								<div className="o-row-2">
									<h3 className="c-section-sub-title">Your Budget</h3>
								</div>
								<div className="o-row-2">
									<p className="c-value-body-copy">
										So, what should you spend on hearing aids? The short answer is get as much technology as you can
										afford. Then, no matter the situation, you're in control and your hearing doesn't slow you down. But
										as mentioned before, any medical-grade hearing aids are better than no hearing aids at all.
									</p>
								</div>
								<div className="o-row">
									<p className="c-value-body-copy">
										A good pair of hearing aids will last a minimum of five years. Monthly payment plans can also make
										them more affordable. Below is an investment breakdown of hearing aids based on technology level. It
										shows the yearly, monthly and daily costs over the lifetime of the devices and how affordable they
										can be. Whether you invest $1, $2 or $3 per day, the most important thing is to do something about
										your hearing now.
									</p>
								</div>
							</div>
							<div className="o-row">
								<div className="c-value-side-by-side__img">
									<img
										src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/price-value/budget.svg"
										className="o-fluid-img"
										loading="lazy"
										alt=""
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="o-row-4p5">
						<div className="c-value-box">
							<div className="c-value-box__icon">
								<img
									src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/price-value/start-quote.svg"
									className="o-fluid-img"
									loading="lazy"
									alt=""
								/>
							</div>
							<p className="c-value-box__copy">
								While I found my hear.com hearing aids to be very affordable, there should never be a price limit on
								your health.<strong>- Paul, HI</strong>
							</p>
							<div className="c-value-box__icon end-quote">
								<img
									src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/price-value/end-quote.svg"
									className="o-fluid-img"
									loading="lazy"
									alt=""
								/>
							</div>
						</div>
					</div>
					<div className="o-row u-text-center">
						<LandingPageCta copy="I want hearing aids I can afford" classes="c-cta c-cta--xl" />
					</div>
				</section>
			</div>
			<div className="o-row-4p5">
				<section className="o-wrapper">
					<div className="o-flush-mobile">
						<img
							src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/price-value/hiking.jpg"
							alt="Woman listening to music"
							loading="lazy"
							className="o-fluid-img"
						/>
						<div className="c-value-showcase">
							<h2 className="c-value-showcase__copy">
								<span className="c-value-showcase__num">3</span> Factors That Can Make A Difference For You
							</h2>
						</div>
					</div>
				</section>
			</div>
			<div className="o-row-4p5">
				<section className="o-wrapper o-wrapper--small">
					<div className="o-row-4p5">
						<p className="c-value-body-copy">
							Everyone wonders, what is the best hearing aid? There actually is no "best" hearing aid. Consumer Reports
							says, "The best hearing aid for you depends on several factors, including the type and severity of your
							hearing loss, your lifestyle and your manual dexterity. However, a hearing aid that one person likes might
							not work for someone else. Therefore, we've identified the two biggest factors that can make a difference
							for you: Advanced technology to create the most effortless, comfortable experience possible, and how well
							your hearing aids are personalized by a trained professional.
						</p>
					</div>
					<div className="o-row-2">
						<h3 className="c-section-sub-title">Professional Personalization</h3>
					</div>
					<div className="o-row-2">
						<p className="c-value-body-copy">
							The best hearing aids are only as good as the person that programs them. Your audiogram (explained below)
							shows the results of your hearing test and provides the amplification you require at different
							frequencies. But how you experience certain sounds is very personal.
						</p>
					</div>
					<div className="o-row-4p5">
						<p className="c-value-body-copy">
							<strong>
								<span className="u-text-italic">Your hearing evaluation and audiogram</span>
							</strong>
							<br />
							This is the first step to personalizing your hearing aids. A licensed professional will conduct a
							comprehensive hearing test with you to produce an audiogram that explains your specific hearing loss. Your
							audiogram will act like your prescription; it will be used to program your hearing aids with the
							amplification needed at each frequency or pitch.
						</p>
					</div>
					<div className="o-row-4p5">
						<div className="c-value-side-by-side">
							<div className="o-row">
								<p className="c-value-body-copy">
									<strong>
										<span className="u-text-italic">Personalized fine-tuning based on your feedback</span>
									</strong>
									<br /> The next step is often overlooked, but is critical to ensure you’re happy with your hearing
									aids long term. How you experience different sounds is very personal and only you know what you like
									or don’t like. Some people prefer a sharper tone, and some softer. Think of it like food... everyone’s
									palate is different, which is why there's a salt shaker on every table. Every person has their ideal
									"saltiness." So even if two people have the same hearing aids with similar settings, they’ll still
									experience sounds differently. That’s why it’s important to provide detailed feedback to your hearing
									care professional so they can fine-tune your hearing aids to your exact preferences.
								</p>
							</div>
							<div className="o-row">
								<div className="c-value-side-by-side__img">
									<img
										src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/price-value/doc.jpg"
										className="o-fluid-img"
										loading="lazy"
										alt=""
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="o-row-2">
						<h3 className="c-section-sub-title">Advanced Technology</h3>
					</div>
					<div className="o-row-2">
						<p className="c-value-body-copy">
							Today’s hearing aids are like powerful, mini-computers. Not only can they be the size of a coffee bean,
							but they need to be extremely fast to deliver real-time amplification as fast as your ears can pick it up,
							with no delay.
						</p>
					</div>
					<div className="o-row-4p5">
						<p className="c-value-body-copy">
							While most now feature modern technology conveniences like smartphone controls, Bluetooth connectivity and
							rechargeability (no batteries!), the technology has continued to advance beyond what anyone could have
							imagined. Every second, they can scan your environment over 10,000 times and process over one billion
							operations — anything less than that you’ll hear echoes. This enables hearing aids to run sophisticated
							algorithms that can deliver a natural, comfortable hearing experience, even in the most challenging
							environments. The more active you are, the more value you will see in higher levels of technology, so your
							hearing aids work smarter to make your hearing effortless.
						</p>
					</div>
					<div className="o-row-2">
						<div className="o-row-2">
							<button className="c-value-dropdown__btn" onClick={handleClick}>
								<div className="c-value-dropdown__btn-left" style={{ pointerEvents: 'none' }}>
									<img
										src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/price-value/Microphone.svg"
										className="c-value-dropdown__btn-img"
										loading="lazy"
										alt=""
									/>
									Speech Enhancement
								</div>
								<div style={{ pointerEvents: 'none' }}>
									<span className="c-value-dropdown__read-more">click to read more</span>
								</div>
							</button>
							<div className="c-value-dropdown__content">
								<p className="c-value-body-copy">
									Improved speech understanding is what hearing aids are optimized for. Advanced features incorporate
									narrow directionality, echo adjustments, and spatial configurators to deliver the highest level of
									speech clarity in the most challenging environments. For example, HD speech algorithms identify and
									amplify speech separately from other sounds which gives you more contrast to better understand speech.
								</p>
							</div>
						</div>
						<div className="o-row-2">
							<button className="c-value-dropdown__btn" onClick={handleClick}>
								<div className="c-value-dropdown__btn-left" style={{ pointerEvents: 'none' }}>
									<img
										src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/price-value/Users.svg"
										className="c-value-dropdown__btn-img"
										loading="lazy"
										alt=""
									/>
									Background Noise Management
								</div>
								<div style={{ pointerEvents: 'none' }}>
									<span className="c-value-dropdown__read-more">click to read more</span>
								</div>
							</button>
							<div className="c-value-dropdown__content">
								<p className="c-value-body-copy">
									Background noise makes it difficult to follow a conversation and to understand every word. Higher
									levels of technology have more sophisticated algorithms that can better identify background noise and
									filter it out (instead of making it louder). In addition, these algorithms can identify and enhance
									speech to make it easier for you to understand people in all types of environments.
								</p>
							</div>
						</div>
						<div className="o-row-2">
							<button className="c-value-dropdown__btn" onClick={handleClick}>
								<div className="c-value-dropdown__btn-left" style={{ pointerEvents: 'none' }}>
									<img
										src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/price-value/ArrowsOut.svg"
										className="c-value-dropdown__btn-img"
										loading="lazy"
										alt=""
									/>
									Directional 360° Sound With Movement
								</div>
								<div style={{ pointerEvents: 'none' }}>
									<span className="c-value-dropdown__read-more">click to read more</span>
								</div>
							</button>
							<div className="c-value-dropdown__content">
								<p className="c-value-body-copy">
									It’s critical to determine where sounds are coming from and what you should listen to. Modern hearing
									aids have acoustic and motion sensors to identify where sounds are coming from in 3D. Then, in a split
									second, sophisticated programs allow the brain to decide who and what to listen to, and how to switch
									attention when needed.
								</p>
							</div>
						</div>
						<div className="o-row-2">
							<button className="c-value-dropdown__btn" onClick={handleClick}>
								<div className="c-value-dropdown__btn-left" style={{ pointerEvents: 'none' }}>
									<img
										src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/price-value/Vibrate.svg"
										className="c-value-dropdown__btn-img"
										loading="lazy"
										alt=""
									/>
									Automatic Environment Detection
								</div>
								<div style={{ pointerEvents: 'none' }}>
									<span className="c-value-dropdown__read-more">click to read more</span>
								</div>
							</button>
							<div className="c-value-dropdown__content">
								<p className="c-value-body-copy">
									Your hearing is influenced by your environment. Modern hearing aids scan your surroundings over 10,000
									times a second and take your movement into consideration, before automatically adjusting their
									programs to deliver an optimal, natural sound experience based on any environment (without you having
									to figure it out). For example, when you sit in a car, the hearing aids will shift their focus to the
									side instead of the front.
								</p>
							</div>
						</div>
						<div className="o-row-2">
							<button className="c-value-dropdown__btn" onClick={handleClick}>
								<div className="c-value-dropdown__btn-left" style={{ pointerEvents: 'none' }}>
									<img
										src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/price-value/Fingerprint.svg"
										className="c-value-dropdown__btn-img"
										loading="lazy"
										alt=""
									/>
									Own Voice Processing
								</div>
								<div style={{ pointerEvents: 'none' }}>
									<span className="c-value-dropdown__read-more">click to read more</span>
								</div>
							</button>
							<div className="c-value-dropdown__content">
								<p className="c-value-body-copy">
									We hear our own voice differently because of where the sound is produced. Modern hearing aids provide
									the most natural experience by processing the wearer’s own voice instantly and independently from all
									other voices and sounds.
								</p>
							</div>
						</div>
						<div className="o-row-2">
							<button className="c-value-dropdown__btn" onClick={handleClick}>
								<div className="c-value-dropdown__btn-left" style={{ pointerEvents: 'none' }}>
									<img
										src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/price-value/BluetoothConnected.svg"
										className="c-value-dropdown__btn-img"
										loading="lazy"
										alt=""
									/>
									Binaural Processing
								</div>
								<div style={{ pointerEvents: 'none' }}>
									<span className="c-value-dropdown__read-more">click to read more</span>
								</div>
							</button>
							<div className="c-value-dropdown__content">
								<p className="c-value-body-copy">
									Binaural processing enables two hearing aids to communicate wirelessly and mimic your brain’s ability
									to process information from both ears. It allows both audio processing and data exchange between the
									hearing aids to operate synchronously and deliver better performance.
								</p>
							</div>
						</div>
						<div className="o-row-2">
							<button className="c-value-dropdown__btn" onClick={handleClick}>
								<div className="c-value-dropdown__btn-left" style={{ pointerEvents: 'none' }}>
									<img
										src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/price-value/Sliders.svg"
										className="c-value-dropdown__btn-img"
										loading="lazy"
										alt=""
									/>
									Tinnitus Therapies
								</div>
								<div style={{ pointerEvents: 'none' }}>
									<span className="c-value-dropdown__read-more">click to read more</span>
								</div>
							</button>
							<div className="c-value-dropdown__content">
								<p className="c-value-body-copy">
									Tinnitus and hearing loss often go hand in hand, with approximately 80% of people suffering from
									tinnitus also having hearing loss. While tinnitus doesn’t cause hearing loss, the perceived sound can
									be distracting and make it hard to concentrate on other sounds. Therefore, tinnitus therapies provide
									different static noise types and ocean wave signals to lend some relief.
								</p>
							</div>
						</div>
						<div className="o-row-2">
							<button className="c-value-dropdown__btn" onClick={handleClick}>
								<div className="c-value-dropdown__btn-left" style={{ pointerEvents: 'none' }}>
									<img
										src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/price-value/BatteryCharging.svg"
										className="c-value-dropdown__btn-img"
										loading="lazy"
										alt=""
									/>
									Wireless + Rechargable
								</div>
								<div style={{ pointerEvents: 'none' }}>
									<span className="c-value-dropdown__read-more">click to read more</span>
								</div>
							</button>
							<div className="c-value-dropdown__content">
								<p className="c-value-body-copy">
									To make your hearing aids even easier to use, many include the benefits of wireless connectivity and
									rechargeability. With wireless connectivity, you can stream phone calls, music or a podcast directly
									to your hearing aids with no additional devices needed. And fully charged, today's rechargeable
									devices will last all day and are more energy efficient, environmentally friendly, and easier on your
									wallet too.
								</p>
							</div>
						</div>
					</div>
					<div className="o-row-2">
						<h3 className="c-section-sub-title">Shortcuts often result in bad experiences</h3>
					</div>
					<div className="o-row-2">
						<p className="c-value-body-copy">
							Customers who are primarily focused on cost often end up with an inferior solution that can't be
							personalized or adjusted over time. Therefore, they continue to struggle as their problems get even worse,
							ultimately resulting in them investing more money into a better solution. Here are the most common
							mistakes to avoid:
						</p>
					</div>
					<div className="o-row-4p5">
						<ul className="c-usps-experience">
							<li className="o-row-2">
								<strong>
									<span className="u-text-italic">
										Watch out for generic, non-medical-grade amplifiers that simply make all sounds louder.
									</span>
								</strong>
								Since they amplify all frequencies by the same amount (even the ones you hear fine), they can actually
								make your hearing worse. You need a medical-grade hearing aid that is personalized to only amplify the
								specific frequencies you struggle with and differentiates between speech and background noise. You
								should always be extremely cautious about putting something into your body that isn't medical-grade.
							</li>
							<li className="o-row-2">
								<strong>
									<span className="u-text-italic">Be careful buying hearing aids without the help of an expert.</span>
								</strong>
								A recommendation from a hearing care professional helps you make a well-informed decision. And if your
								hearing aids aren’t properly programmed from the start and you can't get them fine-tuned, you'll be
								frustrated with the end result.
							</li>
							<li className="o-row-2">
								<strong>
									<span className="">Be wary of the bigger chains.</span>
								</strong>
								They have limited options, don't always carry the newest inventory and require you to return to their
								offices for all follow-up care. Even if they have a professional on site, they can only recommend the
								products they carry, which may not be the best fit for you.
							</li>
							<li className="o-row-2">
								<strong>
									<span className="u-text-italic">
										Beware of buying hearing aids directly online or through mail order without professional hearing
										care.
									</span>
								</strong>
								Medical-grade hearing aids are required by law to be properly programmed and adjusted by licensed
								professionals to suit your specific needs and preferences. If they're not sold through a licensed
								provider, the manufacturer will suspend the warranty for loss and damages.
							</li>
						</ul>
					</div>
					<div className="o-row-4p5">
						<div className="c-value-box">
							<div className="c-value-box__icon">
								<img
									src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/price-value/start-quote.svg"
									loading="lazy"
									className="o-fluid-img"
								/>
							</div>
							<p className="c-value-box__copy">
								I first ordered cheap hearing aids from another online company and they broke all the time and didn't
								really work. I finally talked to hear.com and I am so happy I did.
								<span className="u-text-italic">The difference in service and quality is drastic.</span>{' '}
								<strong>- Greg, MI</strong>
							</p>
							<div className="c-value-box__icon end-quote">
								<img
									src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/price-value/end-quote.svg"
									loading="lazy"
									className="o-fluid-img"
								/>
							</div>
						</div>
					</div>
					<div className="o-row u-text-center">
						<LandingPageCta copy="I'm ready to experience the difference!" classes="c-cta c-cta--xl" />
					</div>
				</section>
			</div>
			<div className="o-row-4p5">
				<section className="o-wrapper">
					<div className="o-flush-mobile">
						<img
							src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/price-value/drinks.jpg"
							alt="Friends drinking together"
							loading="lazy"
							className="o-fluid-img"
						/>
						<div className="c-value-showcase">
							<h2 className="c-value-showcase__copy">
								<span className="c-value-showcase__num">4</span> One of the Smartest Investments You Can Make
							</h2>
						</div>
					</div>
				</section>
			</div>
			<div className="o-row-4p5">
				<section className="o-wrapper o-wrapper--small">
					<div className="o-row-4p5">
						<p className="c-value-body-copy">
							Once you find the best solution for you, your hearing aids will be the most-used device you own. You'll
							wear them from morning till night, racking up 12 to 16 hours per day. You'll also use them with other
							devices like your phone, TV and radio to improve those experiences as well. With this amount of use, it
							makes sense to invest accordingly to ensure you are satisfied. If you settle for a cheaper solution to
							save some money, there's a good chance they won't meet your needs or expectations, and honestly, you won't
							use them.
						</p>
					</div>
					<div className="o-row-4p5">
						<div className="c-value-side-by-side c-value-side-by-side--alt">
							<div className="o-row">
								<div className="o-row-2">
									<h3 className="c-section-sub-title">
										Compared to other expenses, hearing aids are more affordable than you think
									</h3>
								</div>
								<div className="o-row-2">
									<p className="c-value-body-copy">
										Breaking down the costs on a monthly and daily basis shows that hearing aids are not just
										affordable, but actually a small investment compared to what you spend on other day-to-day expenses,
										such as cars, entertainment, or the gym.
									</p>
								</div>
							</div>
							<div className="o-row-2">
								<div className="c-value-side-by-side__img">
									<img
										src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/price-value/budget-arrow.svg"
										loading="lazy"
										className="o-fluid-img"
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="o-row-2">
						<h3 className="c-section-sub-title">Preserve the hearing you still have</h3>
					</div>
					<div className="o-row-4p5">
						<p className="c-value-body-copy">
							Like many others, you may be thinking, “My hearing loss isn't that bad yet.” This is not a good reason to
							delay getting hearing aids. Once your hearing has deteriorated, you can never get it back. It's best to
							act early to preserve the hearing you have and take advantage of the most discreet designs while you still
							can. Hearing aids are the only proven way to preserve and protect the hearing you have left.
						</p>
					</div>
					<div className="o-row-2">
						<h3 className="c-section-sub-title">Stay mentally sharp and healthy</h3>
					</div>
					<div className="o-row-4p5">
						<p className="c-value-body-copy">
							As mentioned before, when you're working harder to hear, you're paying the price in other ways, which
							could lead to even bigger problems down the road. To stay mentally sharp and healthy, your brain should be
							effortlessly gathering information from your senses. This allows you to put your brainpower towards
							activities like processing, thinking and memory. One study by the American Journal of Audiologists showed
							that new hearing aid wearers improved working memory, selective attention and brain processing speeds. And
							additional studies show that hearing aids reduce the risks of cognitive decline and dementia associated
							with hearing loss — think about it as a fitness program for your brain.
						</p>
					</div>
					<div className="o-row-4p5">
						<div className="c-value-side-by-side">
							<div className="o-row">
								<div className="c-value-side-by-side__img c-value-side-by-side__img--left">
									<img
										src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/price-value/whispering.jpg"
										alt="Girl whispering to mother"
										loading="lazy"
										className="o-fluid-img"
									/>
								</div>
							</div>
							<div className="o-row-0">
								<div className="o-row-2">
									<h3 className="c-section-sub-title">Consider the people you care about</h3>
								</div>
								<div className="o-row-0">
									<p className="c-value-body-copy">
										Good communication is essential to your quality of life and the people you care about. It's critical
										to understand your spouse, kids, grand kids, clients and boss — and for them to connect with you.
										Relationships can suffer when communication is difficult. An investment in your hearing will have an
										immediate and direct impact on your life, and on the most important people around you.
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="o-row-2">
						<h3 className="c-section-sub-title">You have nothing to lose with a no-risk trial</h3>
					</div>
					<div className="o-row-4p5">
						<p className="c-value-body-copy">
							The only way to understand what a difference medical-grade hearing aids can have on your life is to “hear
							it for yourself.” With a no-risk trial, you have 45 days to try the devices for yourself in your everyday
							life. If you're like most of our customers after 45 days, you'll wish you did it sooner. If you're not
							satisfied, you can try different hearing aids or you can return them for a full refund.
						</p>
					</div>
					<div className="o-row-4p5 u-text-center">
						<LandingPageCta copy="Try it for 45 days!" classes="c-cta" />
					</div>
					<div className="o-row-2">
						<h3 className="c-section-sub-title">Listen to what our customers have to say</h3>
					</div>
					<VideoIframe
						title="Stories and Testimonials from hear.com Customers - Hear Well | Live Well"
						width="560"
						height="315"
						src="https://www.youtube.com/embed/eihPzvlUn_s"
					/>
				</section>
			</div>
		</Layout>
	);
};

export default GuideToPriceValue;
